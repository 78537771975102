@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'fonts.css';
@import 'datepicker.css';
@import 'react-select.css';
@import 'content-editor.css';
@import 'timepicker.css';
@import 'timezone-picker.css';
@import 'public-block.css';
@import 'circular-progress.css';
@import 'city-picker.css';

body,
html {
  overscroll-behavior-y: none;
  @apply bg-grey-bright antialiased;
}

body.no-scroll {
  height: 100vh;
  overflow-y: hidden;
}

.milestone-step:not(:only-child)::before {
  content: '';
  @apply bg-grey-light;
  width: 2px;
  height: 100%;
  position: absolute;
  margin-left: 4px;
}

.milestone-step:not(:only-child):first-child::before {
  top: 45%;
}

.milestone-step:not(:only-child):last-child::before {
  bottom: 55%;
}

.icon-xxs {
  width: 12px;
  height: 12px;
}

.icon-xs {
  width: 14px;
  height: 14px;
}

.icon-sm {
  width: 16px;
  height: 16px;
}

.icon-base {
  width: 20px;
  height: 20px;
}

.icon-md {
  width: 24px;
  height: 24px;
}

.icon-lg {
  width: 32px;
  height: 32px;
}

.icon-xl {
  width: 40px;
  height: 40px;
}

.icon-xxl {
  width: 80px;
  height: 80px;
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

.prose.navy-dark * {
  @apply text-navy-dark;
}

.prose.white * {
  @apply text-white;
}

.EZDrawer__container {
  z-index: 9999 !important;
}

.EZDrawer__overlay {
  z-index: 9998 !important;
}

.mentions__mention {
  @apply pointer-events-none relative bottom-[2px] z-10 bg-white pl-[2px] text-purple-base !important;
}

.mentions__mention__trigger {
  @apply cursor-pointer !important;
}

.markdown-chat .mentions__mention__trigger {
  @apply !font-semibold;
}

.markdown-default .mentions__mention__trigger {
  @apply !text-purple-light;
}

.tippy-box {
  border-radius: 16px !important;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.05) !important;
}

.use-visible-on-hover .visible-on-hover {
  @apply hidden;
}
.use-visible-on-hover:hover .visible-on-hover {
  @apply flex;
}

.ce-popover__item-icon svg.custom-svg-icon {
  @apply p-1;
}

.notifications-button {
  @apply flex w-full items-center p-3 text-grey-dark hover:text-navy-dark;
}

.notifications-button > div:first-child {
  @apply w-full;
}

a[aria-label='Notifications'] {
  @apply !flex !w-full items-center;
}

a[aria-label='Notifications'] > div:first-child {
  @apply grow;
}

a[aria-label='Notifications'] .unread-badge,
.unread-badge {
  @apply !float-right flex h-6 min-w-[24px] items-center justify-center rounded-full bg-purple-base !px-2 !font-medium text-white ring-2 ring-white !font-sans text-sm;
}
