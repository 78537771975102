@font-face {
  font-family: 'Matter';
  src: url('/fonts/Matter/Matter-MediumItalic.woff2') format('woff2'),
    url('/fonts/Matter/Matter-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Matter';
  src: url('/fonts/Matter/Matter-SemiBoldItalic.woff2') format('woff2'),
    url('/fonts/Matter/Matter-SemiBoldItalic.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Matter';
  src: url('/fonts/Matter/Matter-RegularItalic.woff2') format('woff2'),
    url('/fonts/Matter/Matter-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Matter';
  src: url('/fonts/Matter/Matter-Medium.woff2') format('woff2'), url('/fonts/Matter/Matter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Matter';
  src: url('/fonts/Matter/Matter-Regular.woff2') format('woff2'),
    url('/fonts/Matter/Matter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Matter';
  src: url('/fonts/Matter/Matter-SemiBold.woff2') format('woff2'),
    url('/fonts/Matter/Matter-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
