/*
  Custom styles for react-select to
  match our mockups at Coleap.
*/

.coleap-select__control {
  @apply h-12 w-full rounded-md border-none bg-none px-4 text-base-none !important;
}

.coleap-select__control--is-focused {
  @apply border-none shadow-none !important;
}

.coleap-select__indicator {
  padding: 0 !important;
}

.coleap-select__placeholder {
  @apply text-grey-base !important;
}

.coleap-select__value-container {
  @apply m-0 flex h-auto p-0 !important;
}

.coleap-select__indicator-separator {
  @apply hidden !important;
}

.coleap-select__single-value {
  @apply py-1.5;
}

.coleap-select__menu {
  @apply rounded-base border border-grey-extra-light px-2 shadow-none !important;
}

.coleap-select__option {
  @apply my-1 cursor-pointer rounded-base !important;
}

.coleap-select__option--is-focused {
  @apply bg-purple-bright !important;
}

.coleap-select__option--is-selected {
  @apply bg-purple-base hover:bg-purple-base !important;
}

.event-select .coleap-select__control {
  @apply bg-grey-bright;
}

.bordered-select {
  @apply rounded-md border border-grey-light ring-opacity-20 focus-within:border-purple-base focus-within:ring focus-within:ring-purple-bright;
}
