.timepicker-popper {
  @apply overflow-scroll border !important;
  max-width: 9rem !important;
}

.timepicker-popper .react-datepicker__header {
  @apply mb-2 ml-2.5 font-medium;
}

.timepicker-popper .react-datepicker__time-list-item {
  @apply rounded-base px-3 py-1.5 text-right;
  font-variant-numeric: tabular-nums !important;
}

.timepicker-popper .react-datepicker__time-list-item:hover {
  @apply cursor-pointer bg-grey-extra-light;
}

.timepicker-wrapper .react-datepicker__input-container input {
  @apply bg-grey-bright pl-3.5 outline-none;
}
