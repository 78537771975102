.ce-block__content,
.ce-toolbar__content {
  max-width: unset;
}

.codex-editor {
  font-size: 1.125rem !important;
  @apply text-navy-dark;
  line-height: 1.875rem !important;
}

.codex-editor a,
.content-paragraph a,
.editor-static-content a {
  @apply text-purple-dark hover:text-purple-base;
}

.inline-code {
  font-size: 1.125rem !important;
  line-height: 1.875rem !important;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !important;
}

.tc-table {
  @apply bg-white text-lg !important;
}

.codex-editor--narrow .ce-toolbar__actions {
  right: 100%;
}

.codex-editor__redactor {
  @apply !pb-0;
}

.ce-popover__item.ce-popover__item--focused {
  @apply bg-purple-bright !important;
}

h2.ce-header {
  @apply pt-8 pb-6 font-medium text-2xl-snug;
}

h3.ce-header {
  @apply pt-8 pb-6 font-medium text-xl-snug;
}

h4.ce-header {
  @apply pt-8 pb-6 font-medium text-lg;
}

.editor-embed.minimized {
  overflow: hidden;
}
.editor-embed.minimized .template-container {
  display: none;
}

.editor-embed .buttons-panel a {
  text-decoration: none;
}

.editor-embed .buttons-panel > div::first-letter {
  text-transform: capitalize;
}

.image-tool--filled {
  @apply table;
}

.image-tool__image {
  @apply !mb-0;
}

.image-tool__caption[contenteditable='false'] {
  @apply border-none bg-none text-center italic text-base;
}

.image-tool__caption[contenteditable='false']:empty {
  @apply hidden;
}

.cdx-block {
  @apply py-2;
}
