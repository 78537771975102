.timezone-select__container {
  @apply -mx-3 mt-10 h-96 rounded-md border border-grey-light bg-white px-3 pt-6 !important;
}

.coleap-timezone-select__control {
  @apply w-full rounded-md border border-grey-light !important;
  padding: 5px 5px 2px !important;
  line-height: 1rem !important;
  min-height: auto !important;
}

.coleap-timezone-select__menu {
  @apply shadow-none !important;
}

.coleap-timezone-select__option {
  @apply rounded-md leading-loose !important;
  padding: 6px 12px 4px !important;
  height: auto !important;
}

.coleap-timezone-select__option:hover {
  @apply cursor-pointer;
}

.coleap-timezone-select__option--is-focused {
  @apply bg-grey-bright !important;
}
