.react-datepicker__input-container input {
  @apply h-12 w-full items-start rounded-md pl-12 outline font-sans text-base-none capsize;
}

.react-datepicker__input-container input:not(.bordered-input) {
  @apply border-none bg-grey-extra-light !important;
}

.react-datepicker__input-container input.bordered-input {
  @apply rounded-md border border-grey-light ring-opacity-20 focus-within:border-purple-base focus-within:ring focus-within:ring-purple-bright !important;
}

.react-datepicker__tab-loop {
  @apply absolute;
}

.react-datepicker-popper {
  @apply z-50 w-72 transform-none rounded-lg border border-grey-light bg-white p-4 text-sm !important;
}

.react-datepicker__month-container {
  @apply flex flex-col;
}

.react-datepicker__month {
  @apply flex flex-col;
}

.react-datepicker__current-month {
  @apply ml-2.5 font-medium text-navy-dark text-lg;
}

.react-datepicker__day--today {
  @apply font-bold ring-2 ring-purple-light text-xl;
}

.react-datepicker__week {
  @apply flex justify-around;
}

.react-datepicker__day-names {
  @apply flex justify-around text-center font-medium text-navy-dark text-xs;
}

.react-datepicker__day-name {
  @apply flex h-8 w-8 items-center justify-center rounded-full py-1;
}

.react-datepicker__navigation {
  @apply absolute top-3.5;
}

.react-datepicker__navigation--previous {
  @apply right-12 flex h-8 w-8 items-start justify-center rounded-full pt-1.5 transition hover:bg-grey-extra-light;
}

.react-datepicker__navigation--next {
  @apply right-4 flex h-8 w-8 items-start justify-center rounded-full pt-1.5 transition hover:bg-grey-extra-light;
}

.react-datepicker__day {
  @apply mb-1 flex h-8 w-8 items-center justify-center rounded-full text-navy-dark transition font-sans text-sm capsize;
}

.react-datepicker__day:hover {
  @apply bg-grey-extra-light;
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-50 hover:bg-none;
}

.react-datepicker__day--outside-month {
  @apply text-grey-base;
}

.react-datepicker__day--in-range {
  @apply bg-grey-light;
}

.react-datepicker__day--in-selecting-range {
  @apply bg-purple-extra-light;
}

.react-datepicker__day--selecting-range-start {
  @apply border-2 border-purple-base bg-white;
}

.react-datepicker__day--selecting-range-end {
  @apply border-2 border-purple-base bg-white;
}

.react-datepicker__day--selected {
  @apply bg-purple-base text-white;
}

.react-datepicker__day--range-start {
  @apply bg-purple-base text-white hover:bg-white hover:text-navy-dark;
}

.react-datepicker__day--range-end {
  @apply bg-purple-base text-white hover:bg-white hover:text-navy-dark;
}

.react-datepicker__aria-live {
  position: absolute;
  clip-path: circle(0);
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  width: 1px;
  white-space: nowrap;
}
