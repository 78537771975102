.public-block-container {
  @apply text-navy-dark;
}

.public-block-container a {
  @apply text-purple-dark hover:text-purple-base;
}

.inline-code {
  background-color: rgba(250, 239, 240, 0.78);
  color: #b44437;
  font-size: 1.125rem !important;
  line-height: 1.875rem !important;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace !important;
}
