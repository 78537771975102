.pac-container {
  @apply rounded-base py-2;
}
.pac-container:after {
  @apply bg-center opacity-30 grayscale;
}
.pac-item {
  @apply border-none py-2 px-3 text-navy-dark text-base;
}
.pac-item-query {
  @apply text-navy-dark text-base;
}
.pac-icon {
  @apply mt-0.5 scale-110;
}
